<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="sendEmailTwo-container">
        <div class="title">发送日报邮件</div>
        <!-- <div class="grey-line1"></div> -->
        <!-- <div class="main-title"> <span>发送日报邮件</span><span></span></div> -->
        <div class="step-container">
            <el-steps style="width: 100%" :active="3" align-center>
                <el-step title="选择邮件模板" icon="el-icon-success"></el-step>
                <el-step title="邮件内容配置" icon="el-icon-success"></el-step>
                <el-step title="服务数据预览"></el-step>
                <el-step title="邮件内容确认"></el-step>
            </el-steps>
        </div>
        <div class="grey-line"></div>
        <div class="main-title"> <span>服务数据预览</span><span>数据日期：{{date}}</span></div>
        <div class="sendEmailTwo-main">
            <div class="main-title"> <span>邮件内容确认</span><span></span></div>
            <el-form class="form-container" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
                <el-form-item label="服务数据预览：" prop="date">
                    <div class="add-btn" @click="() => { $router.push('/data/customer-service-analysis') }">点击跳转上传数据页面</div>
                    <div class="tip-text">"/"为当前三圆无权限/无官方途径查询服务数据、当天无接待量导致无数或者暂时未开账号</div>
                    <div class="service-table">
                        <el-table class="common-table" style="width: 98%;margin: 0" :span-method="objectSpanMethod" border :data="serviceKpi">
                            <el-table-column prop="departmentName" label="部门" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="shopName" label="店铺名称" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="loginPlatform" label="平台" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="shopConsultationNum" class-name="bg-col" label="总接待量" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="frequency" label="班次" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="loginAccount" label="子账号" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="consultationNum" label="子账号咨询量" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="firsrTime" label="首次响应时间" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="avgTime" label="平均响应" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="qaRate" label="问答比" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="conversionRate" label="转化率" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="replyRate" label="回复率" :formatter="tableColumn2"></el-table-column>
                        </el-table>
                    </div>
                    <!-- <el-date-picker class="form-input" v-model="ruleForm.date" type="date" value-format="yyyy-MM-dd" placeholder="请选择数据日期"></el-date-picker> -->
                </el-form-item>
                <el-form-item label="质检异常记录：" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
                    <el-table class="common-table" style="width: 98%;margin: 0" border :data="hisNotList">
                        <el-table-column prop="platformName" label="聊天日期" :formatter="tableColumn">
                            <template slot-scope="">
                                <span>{{date}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="qualityTime" label="质检日期">
                            <template slot-scope="scope">
                                <span>{{formatDateTimer(scope.row.qualityTime)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="qualityName" label="质检人员" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="shopName" label="店铺名称" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="serviceInspected" label="客服姓名" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="subaccountName" label="店铺子账号" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="customerAccount" label="顾客账号" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="classificationQuality.bigList" label="客服问题大类" show-overflow-tooltip width="120"></el-table-column>
                        <el-table-column prop="classificationQuality.pointList" label="客服问题点" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="classificationQuality.descList" label="问题点详细" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="amount" label="扣款" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="pointDeduction" label="扣分值" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="score" label="分值" :formatter="tableColumn"></el-table-column>
                        <!-- <el-table-column label="服务客服数量" :formatter="tableColumn">
                            <template slot-scope="scope">
                                <el-form-item :rules="[{ required: true, message: ' ', trigger: 'change' }]" :prop="`shopList[${scope.$index}].number`">
                                    <el-input placeholder="请输入派遣客服数量" v-model.trim="scope.row.number"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </el-form-item>
                <el-form-item label="质检记录：" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
                    <el-table class="common-table" style="width: 98%;margin: 0" border :data="uploadTable">
                        <el-table-column label="服务店铺名称" prop="shopName" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="平台" prop="platformName" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="服务店铺数据确认" prop="fileName">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.fileName" @click="() => { lookFileFun(scope.row.fileUrl) }" type="text">{{scope.row.fileName}}</el-button>
                                <span v-else>--</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="服务数据确认上传">
                            <template slot-scope="scope">
                                <div class="uploadBtn-container">
                                    上传数据
                                    <input @input="(e) => { uploadFun(e, scope.$index) }" class="upload-input" type="file" />
                                </div>
                                <!-- <span>{{}}</span> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="grey-line"></div>
        <div class="sendEmailTwo-bottom">
            <el-button class="common-screen-btn" plain @click="() => { $router.push(`/mail/setting`) }">取消发送</el-button>
            <div>
                <el-button class="common-screen-btn" plain @click="() => { $router.push(`/mail/setting/sendEmailOne?modelId=${modelId}&date=${date}&modelName=${modelName}`) }">返回邮件内容配置</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { addEmailUrl() }">下一步：邮件内容配置</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { tableColumn, duplicateRemoval, tableColumn2, formatDateTimer, Config  } from "../../utils/index.js"
import { emailSerKPI, custShopList, emailDepartList, qualityHisNot, addEmailUrl } from "../../service/dataInfo.js"
import { upload } from "../../service/upload.js"
export default {
    data() {
        return {
            tableColumn,
            tableColumn2,
            formatDateTimer,
            loading: false,
            date: this.$route.query.date,
            modelId: this.$route.query.modelId,
            modelName: this.$route.query.modelName,
            ruleForm: {},
            rules: { // 校验
                date: [{ required: true, message: "请选择日期", trigger: "change" }],
            },
            serviceKpi: [], // 服务数据概览
            shopIdsArr: [], // 获取店铺
            hisNotList: [], // 异常质检记录列表
            uploadTable: [], // 店铺服务数据上传列表
        }
    },
    mounted () {
        this.custShopList().then(() => {
            let shopIds = this.shopIdsArr
            this.emailSerKPI(shopIds)
            this.qualityHisNot(shopIds)
        })
    },
    methods: {
        async addEmailUrl () {
            let uploadTable = JSON.parse(JSON.stringify(this.uploadTable))
            let fileList = []
            let isFileUrl = true
            uploadTable.map((item) => {
                if (item.fileUrl) {
                    fileList.push(item.fileUrl)
                } else {
                    isFileUrl = false
                }
            })
            if (!isFileUrl) {
                return this.$message.error("请先上传质检记录")
            }
            let params = {
                fileList,
                emailModelId: this.modelId,
                dateTime: this.date
            }
            this.loading = true
            let { code } = await addEmailUrl(params)
            this.loading = false
            if (code == 200) {
                this.$message.success("提交成功")
                this.$router.push(`/mail/setting/sendEmailThree?modelId=${this.modelId}&date=${this.date}&modelName=${this.modelName}`)
            }
        },
        lookFileFun (url) { // 预览文件
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${url}`)
        },
        async uploadFun (e, index) { // 上传文件
            let uploadTable = JSON.parse(JSON.stringify(this.uploadTable))
            let file = e.target.files[0];
            let format = file.name.split(".")[file.name.split(".").length - 1]
            let renameFile = new File([file],  `${uploadTable[index].shopName || ''}-${uploadTable[index].platformName || ''}-质检记录.${format}`)
            if (!Config.fileType.excelType.includes(format)) {
                this.$message.error(`仅支持${Config.fileMessage.excelType}格式文件`)
                e.target.value = ""
                return
            }
            this.loading = true
            let { code, data } = await upload({ file: renameFile })
            this.loading = false
            e.target.value = ""
            if(code == 200){
                if (data && data.filename) {
                    uploadTable[index].fileName = renameFile.name
                    uploadTable[index].fileUrl = data.filename
                    this.uploadTable = uploadTable
                }
                this.$message.success("上传成功")
            }
        },
        async qualityHisNot (shopIds) { // 质检记录异常列表
            let params = {
                emailModelId: this.modelId,
                startDate: this.date,
                adopt: '不通过',
                shopIds: shopIds
            }
            this.loading = true
            let { data } = await qualityHisNot(params)
            this.loading = false
            let hisNotList = []
            if (data && data.length) {
                data.map((item) => {
                    if (item.qualityHisNot && item.qualityHisNot.length) {
                        item.qualityHisNot.map((itemTwo) => {
                            hisNotList.push(itemTwo)
                        })
                    }
                })
            }
            this.hisNotList = hisNotList
        },
        objectSpanMethod({ row, columnIndex }) { // 合并表格
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 3) {
                if (row.spanRow || row.spanRowShop) {
                    return {
                        rowspan: columnIndex == 0 ? row.spanRow : row.spanRowShop,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        async custShopList() { // 获取店铺信息 
            let { data } = await custShopList({ id: this.modelId })
            let shopIds = []
            if (data && data.length) {
                data.map((item) => {
                    shopIds.push(item.shopId)
                    item.fileName = ''
                })
            }
            this.uploadTable = data
            this.shopIdsArr = shopIds
            // this.emailSerKPI(shopIds)
        },
        async emailSerKPI (shopIds) { // 服务数据
            let params = {
                startDate: this.date,
                // endDate: this.date,
                emailModelId: this.modelId,
                shopIds: shopIds
            }
            this.loading = true
            let { data } = await emailSerKPI(params)
            let departList = (await emailDepartList(params)).data || []
            this.loading = false
            let serviceKpi = []
            let serviceKpiNull = []
            departList.map((item) => {
                if (item.shopIds) {
                    let shopIdsArr = item.shopIds.split(',')
                    shopIdsArr.map((itemTwo) => {
                        data.map((itemThree, indexThree) => {
                            if (itemThree.account && itemThree.account.length) {
                                itemThree.account.map((itemfour, indexFour) => {
                                    if (itemTwo == itemfour.shopId) {
                                        serviceKpi.push( Object.assign({ ...item }, { ...itemfour, shopConsultationNum: itemThree.shopConsultationNum, spanRowShop: indexFour == 0 ? itemThree.account.length : 0 }) )
                                    } else {
                                        if (data.length != (indexThree + 1)) {
                                            serviceKpiNull.push( { ...itemfour, shopConsultationNum: itemThree.shopConsultationNum, departmentName: "/", spanRowShop: indexFour == 0 ? itemThree.account.length : 0 } )
                                        }
                                    }
                                })
                            }
                            if (data.length == (indexThree + 1)) {
                                serviceKpiNull.push( { departmentName: 'TTL', shopConsultationNum: itemThree.allNum, spanRow: 1, spanRowShop: 1 } )
                            }
                        })
                    })
                }
            })
            serviceKpi = serviceKpi.concat(serviceKpiNull)
            serviceKpi = duplicateRemoval(serviceKpi, 'id')
            serviceKpi.reverse()
            let spanRowNull = 0
            let numNull = 0
            serviceKpi.map((item, index) => {
                if (item.departmentName == '/') {
                    spanRowNull++
                    numNull = index
                }
            })
            serviceKpi[numNull].spanRow = spanRowNull
            departList.map((item) => {
                let spanRow = 0
                let num = 0
                serviceKpi.map((itemTwo, indexTwo) => {
                    if (item.departmentName == itemTwo.departmentName) {
                        spanRow++
                        num = indexTwo
                    }
                })
                serviceKpi[num].spanRow = spanRow
            })
            serviceKpi.reverse()
            this.serviceKpi = serviceKpi
        }
    }
}
</script>
<style lang="less" scoped>
.sendEmailTwo-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    /deep/.el-button--text {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .uploadBtn-container {
        height: 32px;
        line-height: 32px;
        width: 88px;
        text-align: center;
        color: #1890ff;
        border: 1px solid #1890ff;
        border-radius: 4px;
        position: relative;
        .upload-input {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            left: 0;
            top: 0;
            opacity: 0;
            // font-size: 0;
        }
    }
    .service-table {
        /deep/.bg-col {
            background: #DCEEFF;
        }
        /deep/.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-color: #5fbfff !important;
        }
        /deep/.el-table td, .el-table th.is-leaf {
            border-color: #5fbfff !important;
        }
        /deep/.el-table--border th {
            border-color: #5fbfff !important;
        }
        .common-table {
            border-color: #5fbfff !important;
        }
    }
    /deep/.el-table--border th {
        background: #f2f4f5;
        padding: 0;
    }
    .tip-text {
        font-size: 14px;
        color: #101010;

    }
    .add-btn {
        height: 38px;
        line-height: 38px;
        border: 1px solid #1890ff;
        padding: 0 8px 0 8px;
        color: #1890ff;
        display: inline-block;
        border-radius: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        position: relative;
        &::after {
            display: block;
            content: '若数据有误或缺失，请在店铺服务分析中上传数据。';
            white-space: nowrap;
            position: absolute;
            left: 110%;
            top: 0;
            bottom: 0;
            margin: auto;
            color: #999;
            font-size: 12px;
        }
    }
    .form-container {
        width: 100%;
        margin: 32px 0;
    }
    .form-input {
        width: 400px;
    }
    .sendEmailTwo-main {
        flex: 1;
        overflow: auto;
        overflow-x: hidden;
    }
    .sendEmailTwo-bottom {
        height: 64px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-top: 16px;
        justify-content: space-between;
    }
    .step-container {
        height: 117px;
        display: flex;
        align-items: center;
        /deep/.el-step__title.is-process {
            color: #C0C4CC;
            font-weight: 500 !important;
        }
        /deep/.el-step__icon.is-text {
            color: #C0C4CC;
            border-color: #C0C4CC;
        }
        /deep/.el-step__head.is-finish .is-text {
            color: #409EFF !important;
            border-color: #409EFF !important;
        }
    }
    .main-title {
        box-sizing: border-box;
        padding: 16px;
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        span {
            color: #101010;
            font-size: 18px;
        }
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
    .grey-line1 {
        height: 1px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
</style>